<template>
  <div class="email">
    <!-- Header img -->
    <b-container fluid>
      <b-row class="mb-4">
        <b-img
          fluid
          :src="
            node_env === 'production'
              ? email.header_bild.data.attributes.url
              : api_url + email.header_bild.data.attributes.url
          "
        ></b-img>
        <h1 class="header-titel">{{ email.header_bild_titel }}</h1>
      </b-row>
    </b-container>

    <b-container>
      <!-- Titel -->
      <b-row>
        <b-col class="email-titel mt-5" md="8">
          <div v-html="email.titel"></div>
          <div class="border-grey"></div>
        </b-col>
      </b-row>

      <!-- Formular -->
      <div class="row formular">
        <div class="col-md-8">
          <div v-html="email.formular"></div>
        </div>
        <b-col class="right-sidebar" offset="1" md="3">
          <ul class="list-unstyled">
            <li>
              <b-img
                width="32"
                class="mb-3"
                fluid
                :src="
                  node_env === 'production'
                    ? email.telefon_bild.data.attributes.url
                    : api_url + email.telefon_bild.data.attributes.url
                "
              ></b-img>
            </li>
            <li v-html="email.telefon_text"></li>
          </ul>
          <ul class="list-unstyled">
            <li>
              <b-img
                width="32"
                class="mb-3"
                fluid
                :src="
                  node_env === 'production'
                    ? email.ausbildung_bild.data.attributes.url
                    : api_url + email.ausbildung_bild.data.attributes.url
                "
              ></b-img>
            </li>
            <li v-html="email.ausbildung_text"></li>
          </ul>
          <ul class="list-unstyled">
            <li>
              <b-img
                width="32"
                class="mb-3"
                fluid
                :src="
                  node_env === 'production'
                    ? email.offnungzeiten_bild.data.attributes.url
                    : api_url + email.offnungzeiten_bild.data.attributes.url
                "
              ></b-img>
            </li>
            <li v-html="email.offnungzeiten_text"></li>
          </ul>
          <ul class="list-unstyled">
            <li>
              <b-img
                width="32"
                class="mb-3"
                fluid
                :src="
                  node_env === 'production'
                    ? email.email_bild.data.attributes.url
                    : api_url + email.email_bild.data.attributes.url
                "
              ></b-img>
            </li>
            <li v-html="email.email_text"></li>
          </ul>
        </b-col>
      </div>
    </b-container>
  </div>
</template>

<style lang="scss">
.email {
  position: relative;
  .header-titel {
    position: absolute;
    left: 50%;
    top: 318px;
    color: white;
  }
}
.email-titel {
  h1 {
    font-weight: 300;
  }
  span {
    color: #777777;
  }
}
.border-grey {
  margin: 25px 0px 25px 0px;
  width: 75px;
  height: 2px;
  background: #eee;
}
.formular {
  margin-bottom: 100px;
  .form-control {
    background: #e9e9e9;
    border: 0px;
    border-radius: 0px;
  }
  p,
  label {
    color: #777;
  }
}
.right-sidebar {
  h6 {
    color: #3aa856;
  }
  ul {
    margin-bottom: 100px;
  }
  p,
  li {
    color: #777;
  }
  a {
    color: #3aa856;
  }
}
@media only screen and (max-width: 600px) {
  .email {
    margin-top: 85px;
  }
}
</style>

<script>
export default {
  name: "Email",
  computed: {
    email() {
      return this.$store.state.email;
    },
    node_env() {
      return this.$store.state.node_env;
    },
    api_url() {
      return this.$store.state.api_url;
    },
  },
};
</script>
