<template>
  <div class="individuelle">
    <!-- Header img -->
    <b-container fluid>
      <b-row class="mb-4">
        <b-img
          fluid
          :src="
            node_env === 'production'
              ? individuelle.header_bild.data.attributes.url
              : api_url + individuelle.header_bild.data.attributes.url
          "
        ></b-img>
        <h1 class="header-titel">{{ individuelle.header_bild_titel }}</h1>
      </b-row>
    </b-container>

    <b-container>
      <!-- Titel -->
      <b-row>
        <b-col class="email-titel mt-5" md="8">
          <div v-html="individuelle.titel"></div>
          <div class="border-grey"></div>
        </b-col>
      </b-row>

      <!-- Formular -->
      <div class="row formular">
        <div class="col-md-12">
          <form action="https://formspree.io/f/mqknlwpn" method="post">
            <!-- Select privat or firma -->
            <div class="form-group mb-4 w-50">
              <label for=""
                ><b
                  >Anmeldeinformationen <span class="text-danger">*</span></b
                ></label
              >
              <small class="form-text text-muted">Privat oder Firma</small>
              <select
                v-model="anmeldeinformation"
                class="form-control"
                name="anmeldeinformationen"
              >
                <option value="firma">FIRMA</option>
                <option value="privatperson">PRIVATPERSON</option>
              </select>
            </div>
            <!-- Firma name -->
            <div
              v-if="anmeldeinformation == 'firma'"
              class="form-group mb-4 w-50"
            >
              <label for=""
                ><b>Firma <span class="text-danger">*</span></b></label
              >
              <input
                required
                type="text"
                class="form-control"
                name="firma_name"
              />
            </div>
            <!-- Privat name -->
            <ul v-else class="list-inline mb-1">
              <li class="list-inline-item">
                <div class="form-group">
                  <label for=""
                    ><b>Name <span class="text-danger">*</span></b></label
                  >
                  <input
                    required
                    type="text"
                    class="form-control"
                    name="vorname"
                  />
                  <small class="form-text text-muted">Vorname</small>
                </div>
              </li>
              <li class="list-inline-item">
                <div class="form-group">
                  <input
                    required
                    type="text"
                    class="form-control"
                    name="nachname"
                  />
                  <small class="form-text text-muted">Nachname</small>
                </div>
              </li>
            </ul>

            <!-- Mutual fields -->

            <!-- Adresse -->
            <div class="form-group mb-4">
              <label for=""
                ><b
                  ><span v-if="anmeldeinformation == 'privatperson'"
                    >Adresse</span
                  >
                  <span v-else>Rechnungsadresse Firma</span>
                  <span class="text-danger">*</span></b
                ></label
              >
              <input required type="text" class="form-control" name="strasse" />
              <small class="form-text text-muted">Strasse</small>
            </div>

            <ul class="list-inline mb-1">
              <li class="list-inline-item">
                <!-- Ort -->
                <div class="form-group">
                  <input required type="text" class="form-control" name="ort" />
                  <small class="form-text text-muted">Ort</small>
                </div>
              </li>

              <li class="list-inline-item">
                <!-- Plz -->
                <div class="form-group">
                  <input required type="text" class="form-control" name="plz" />
                  <small class="form-text text-muted">PLZ</small>
                </div>
              </li>
            </ul>

            <!-- Land -->
            <div class="form-group mb-4 w-50">
              <select class="form-control" name="land">
                <option selected value="Switzerland">Switzerland</option>
                <option
                  v-for="country in countries"
                  :key="country.index"
                  :value="country.name.common"
                >
                  {{ country.name.common }}
                </option>
              </select>
              <small class="form-text text-muted">Land</small>
            </div>

            <!-- Telefon -->
            <div class="form-group mb-4">
              <label for=""
                ><b
                  >Telefon
                  <span v-if="anmeldeinformation == 'firma'">Firma</span>
                  <span v-else>Privat</span>
                  <span class="text-danger">*</span></b
                ></label
              >
              <input
                required
                type="text"
                class="form-control w-50"
                name="telefon"
              />
            </div>

            <!-- Mobile -->
            <div
              v-if="anmeldeinformation == 'privatperson'"
              class="form-group mb-4"
            >
              <label for=""><b>Mobile </b></label>
              <input type="text" class="form-control w-50" name="mobile" />
            </div>

            <!-- E-mail -->
            <div class="form-group mb-4">
              <label for=""
                ><b
                  >E-mail
                  <span v-if="anmeldeinformation == 'firma'">Firma</span></b
                ><span class="text-danger">*</span></label
              >
              <input
                required
                type="email"
                class="form-control w-50"
                name="email"
              />
            </div>

            <!-- Private selected -->
            <div v-if="anmeldeinformation == 'privatperson'">
              <!-- Gerbutrsdatum Privat-->
              <div class="form-group mb-4">
                <label for=""
                  ><b>Gerbutrsdatum </b
                  ><span class="text-danger">*</span></label
                >
                <input
                  required
                  placeholder="tt.mm.jjjj"
                  type="text"
                  class="form-control w-50"
                  name="geburtsdatum"
                />
              </div>

              <!-- AHV Privat-->
              <div class="form-group mb-4">
                <label for=""
                  ><b>AHV Versichertennr. </b
                  ><span class="text-danger">*</span></label
                >
                <input
                  required
                  type="text"
                  class="form-control w-50"
                  name="ahv-nr"
                />
              </div>

              <!-- CZV-asa Eintrag -->
              <div class="form-group mb-4 w-50">
                <label for=""><b>CZV-asa Eintrag</b></label>
                <select v-model="czv" class="form-control" name="czv-eintrag">
                  <option selected value="NEIN">NEIN</option>
                  <option value="JA">JA</option>
                </select>
              </div>

              <!-- FAK NR ( IF CZV == JA) -->
              <div v-if="czv == 'JA'" class="form-group mb-4">
                <label for=""
                  ><b>FAK-Nummer </b><span class="text-danger">*</span></label
                >
                <input
                  required
                  type="text"
                  class="form-control w-50"
                  name="fak-nr"
                />
              </div>

              <!-- E-learning email -->
              <div class="form-group mb-4">
                <label for=""><b>E-Mail für E-Learning</b></label>
                <input
                  type="email"
                  class="form-control w-50"
                  name="e-learning-email"
                />
              </div>
            </div>

            <!-- if Firma -->

            <!-- Anzahl Personen -->
            <div class="form-group mb-4">
              <label for=""
                ><b>Anzahl Personen </b
                ><span class="text-danger">*</span></label
              >
              <small class="form-text text-muted"
                >Wieivele Personen möchten Sie für den Kurs anmelden?</small
              >
              <select
                v-model="anzahl_personen"
                class="form-control w-50"
                name="anzahl_personen"
              >
                <option selected value="1">1 PERSON</option>
                <option value="2">2 PERSONEN</option>
                <option value="3">3 PERSONEN</option>
                <option value="4">4 PERSONEN</option>
                <option value="5">5 PERSONEN</option>
                <option value="6">6 PERSONEN</option>
                <option value="7">7 PERSONEN</option>
                <option value="8">8 PERSONEN</option>
                <option value="9">9 PERSONEN</option>
                <option value="10">10 PERSONEN</option>
                <option value="11">11 PERSONEN</option>
                <option value="12">12 PERSONEN</option>
                <option value="13">13 PERSONEN</option>
                <option value="14">14 PERSONEN</option>
                <option value="15">15 PERSONEN</option>
                <option value="16">16 PERSONEN</option>
              </select>
            </div>

            <!-- Looped Vorname/Nachname/Geburtsdarum/Ahv/CZV/Email fur learning field x(anzahl_personen) -->
            <div
              v-for="field in dynamicallyLoopedUserFields"
              :key="field.index"
            >
              <div v-html="field"></div>
            </div>

            <!-- Mutual fields -->

            <!-- Bemerkung -->
            <div class="form-group mb-4">
              <label for=""><b>Bemerkung</b></label>
              <input type="text" class="form-control py-5" name="bemerkung" />
            </div>

            <!-- AGB checkbox -->
            <div class="form-check mb-4">
              <h6 style="margin-left: -20px">
                <b>AGB <span class="text-danger">*</span></b>
              </h6>
              <label class="form-check-label">
                <input
                  required
                  type="checkbox"
                  class="form-check-input"
                  name="agb-checkbox"
                  value="agb"
                />
                Ich akzeptiere hiermit die
                <router-link to="/agb">
                  Allgemeinen Geschäftsbedingungen</router-link
                >
              </label>
            </div>

            <button class="btn btn-success rounded-0 mb-5" type="submit">
              Senden
            </button>
          </form>
        </div>
      </div>
    </b-container>
  </div>
</template>

<style lang="scss">
.individuelle {
  position: relative;
  .header-titel {
    position: absolute;
    left: 50%;
    top: 318px;
    color: white;
  }
}
.formular {
  margin-bottom: 100px;
  .form-control {
    background: #e9e9e9;
    border: 0px;
    border-radius: 0px;
  }
  p,
  label,
  h6 {
    color: #777;
  }
  a {
    color: #00b252;
  }
}
@media only screen and (max-width: 600px) {
  .individuelle {
    margin-top: 85px;
  }
}
</style>

<script>
import axios from "axios";
export default {
  name: "IndividuelleAnmeldung",
  mounted() {
    // get countries list
    axios.get("https://restcountries.com/v3.1/all").then((response) => {
      this.countries = response.data;
    });
  },
  data() {
    return {
      anmeldeinformation: "firma",
      countries: [],
      selected_country: "Switzerland",
      czv: "NEIN",
      selected_czv: 0,
      czv_anzahl: 0,
      anzahl_personen: 1,
    };
  },
  methods: {
    calcCzv(nr_of_czv) {
      this.czv_anzahl = nr_of_czv;
    },
  },

  computed: {
    individuelle() {
      return this.$store.state.individuelle;
    },
    api_url() {
      return this.$store.state.api_url;
    },
    node_env() {
      return this.$store.state.node_env;
    },
    dynamicallyLoopedUserFields() {
      let dynamicallyLoopedUserFields = [];

      for (let i = 0; i < this.anzahl_personen; i++) {
        dynamicallyLoopedUserFields.splice(
          i,
          0,
          `
          <!-- Vorname Nachname -->
          <ul class="list-inline mb-1">
              <li class="list-inline-item">
                <div class="form-group">
                  <label for=""
                    ><b>Name (P${
                      i + 1
                    })<span class="text-danger">*</span></b></label
                  >
                  <input
                    required
                    type="text"
                    class="form-control"
                    name="vorname_p${i + 1}"
                  />
                  <small class="form-text text-muted">Vorname</small>
                </div>
              </li>
              <li class="list-inline-item">
                <div class="form-group">
                  <input
                    required
                    type="text"
                    class="form-control"
                    name="nachname_p${i + 1}"
                  />
                  <small class="form-text text-muted">Nachname</small>
                </div>
              </li>
            </ul>


            <!-- Geburtsdatum -->
               <div class="form-group mb-4">
                <label for=""
                  ><b>Gerbutrsdatum P(${i + 1}) </b
                  ><span class="text-danger">*</span></label
                >
                <input
                  required
                  placeholder="tt.mm.jjjj"
                  type="text"
                  class="form-control w-50"
                  name="geburtsdatum_p${i + 1}"
                />
              </div>

                <!-- AHV NR -->
                <div class="form-group mb-4">
                <label for=""
                  ><b>AHV Versichertennr. P(${i + 1})</b
                  ><span class="text-danger">*</span></label
                >
                <input
                  required
                  type="text"
                  class="form-control w-50"
                  name="ahv-nr-p${i + 1}"
                />
              </div>

              <!-- CZV-asa Eintrag -->
              <div class="form-group mb-4 w-50">
                <label for=""><b>CZV-asa Eintrag P(${i + 1})</b></label>
                <select class="form-control" name="czv-eintrag-p${i + 1}">
                  <option selected value="NEIN">NEIN</option>
                  <option value="JA">JA</option>
                </select>
              </div>

              <!-- E-learning email -->
              <div class="form-group mb-4">
                <label for=""><b>E-Mail für E-Learning P(${i + 1})</b></label>
                <input
                  type="email"
                  class="form-control w-50"
                  name="e-learning-email-p${i + 1}"
                />
              </div>
            `
        );
      }
      return dynamicallyLoopedUserFields;
    },
  },
};
</script>
