var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"email"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"mb-4"},[_c('b-img',{attrs:{"fluid":"","src":_vm.node_env === 'production'
            ? _vm.email.header_bild.data.attributes.url
            : _vm.api_url + _vm.email.header_bild.data.attributes.url}}),_c('h1',{staticClass:"header-titel"},[_vm._v(_vm._s(_vm.email.header_bild_titel))])],1)],1),_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"email-titel mt-5",attrs:{"md":"8"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.email.titel)}}),_c('div',{staticClass:"border-grey"})])],1),_c('div',{staticClass:"row formular"},[_c('div',{staticClass:"col-md-8"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.email.formular)}})]),_c('b-col',{staticClass:"right-sidebar",attrs:{"offset":"1","md":"3"}},[_c('ul',{staticClass:"list-unstyled"},[_c('li',[_c('b-img',{staticClass:"mb-3",attrs:{"width":"32","fluid":"","src":_vm.node_env === 'production'
                  ? _vm.email.telefon_bild.data.attributes.url
                  : _vm.api_url + _vm.email.telefon_bild.data.attributes.url}})],1),_c('li',{domProps:{"innerHTML":_vm._s(_vm.email.telefon_text)}})]),_c('ul',{staticClass:"list-unstyled"},[_c('li',[_c('b-img',{staticClass:"mb-3",attrs:{"width":"32","fluid":"","src":_vm.node_env === 'production'
                  ? _vm.email.ausbildung_bild.data.attributes.url
                  : _vm.api_url + _vm.email.ausbildung_bild.data.attributes.url}})],1),_c('li',{domProps:{"innerHTML":_vm._s(_vm.email.ausbildung_text)}})]),_c('ul',{staticClass:"list-unstyled"},[_c('li',[_c('b-img',{staticClass:"mb-3",attrs:{"width":"32","fluid":"","src":_vm.node_env === 'production'
                  ? _vm.email.offnungzeiten_bild.data.attributes.url
                  : _vm.api_url + _vm.email.offnungzeiten_bild.data.attributes.url}})],1),_c('li',{domProps:{"innerHTML":_vm._s(_vm.email.offnungzeiten_text)}})]),_c('ul',{staticClass:"list-unstyled"},[_c('li',[_c('b-img',{staticClass:"mb-3",attrs:{"width":"32","fluid":"","src":_vm.node_env === 'production'
                  ? _vm.email.email_bild.data.attributes.url
                  : _vm.api_url + _vm.email.email_bild.data.attributes.url}})],1),_c('li',{domProps:{"innerHTML":_vm._s(_vm.email.email_text)}})])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }