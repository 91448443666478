<template>
  <div class="mt-5">
    <TheMenu />
    <transition name="route">
      <router-view />
    </transition>
    <TheFooter />
  </div>
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,700;1,400&display=swap");
* {
  font-family: "Montserrat", sans-serif;
}

.copyright-text {
  a {
    color: #00b252;
  }
}

// Transition effect
.route-enter,
.route-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}
.route-enter-active,
.route-leave-acitve {
  transition: all 0.7s ease;
}
.route-enter-to,
.route-leave {
  opacity: 1;
  transform: translateY(0);
}

@media only screen and (max-width: 600px) {
  .b-calendar {
    margin-top:80px;
  }
}
</style>

<script>
import axios from "axios";
import TheMenu from "@/components/TheMenu.vue";
import TheFooter from "@/components/TheFooter.vue";
export default {
  name: "App",
  components: { TheMenu, TheFooter },
  mounted() {
    /** Make concurrent HTTP requests using Axios all
     * and get back an equal number of responses,
     * then commit the response to respective Vuex states
     */
    axios
      .all([
        axios.get(`${process.env.VUE_APP_URL}/api/startseite?populate=*`),
        axios.get(`${process.env.VUE_APP_URL}/api/footer?populate=*`),
        axios.get(`${process.env.VUE_APP_URL}/api/galerie?populate=*`),
        axios.get(`${process.env.VUE_APP_URL}/api/uber-uns?populate=*`),
        axios.get(`${process.env.VUE_APP_URL}/api/teams?populate=*`),
        axios.get(`${process.env.VUE_APP_URL}/api/agb?populate=*`),
        axios.get(`${process.env.VUE_APP_URL}/api/email?populate=*`),
        axios.get(`${process.env.VUE_APP_URL}/api/individuelle?populate=*`),
        axios.get(`${process.env.VUE_APP_URL}/api/kurses?populate=*`),
        axios.get(`${process.env.VUE_APP_URL}/api/buchungens?populate=*`),
        axios.get(`${process.env.VUE_APP_URL}/api/kursseiten?populate=*`),
        axios.get(`${process.env.VUE_APP_URL}/api/vermietung?populate=*`),
        axios.get(`${process.env.VUE_APP_URL}/api/job?populate=*`),
      ])
      .then((responseArr) => {
        // Commit data to Vuex store

        this.commitApiData("startseite", responseArr[0].data.data.attributes);
        this.commitApiData("footer", responseArr[1].data.data.attributes);
        this.commitApiData("galerie", responseArr[2].data.data.attributes);
        this.commitApiData("uber_uns", responseArr[3].data.data.attributes);
        this.commitApiData(
          "teams",
          responseArr[4].data.data.map((item) => item.attributes)
        );
        this.commitApiData("agb", responseArr[5].data.data.attributes);
        this.commitApiData("email", responseArr[6].data.data.attributes);
        this.commitApiData("individuelle", responseArr[7].data.data.attributes);
        this.commitApiData(
          "kurses",
          responseArr[8].data.data.map((item) => item.attributes)
        );
        this.commitApiData(
          "buchungens",
          responseArr[9].data.data.map((item) => item.attributes)
        );
        this.commitApiData("kursseiten", responseArr[10].data.data.attributes);
        this.commitApiData("vermietung", responseArr[11].data.data.attributes);
        this.commitApiData("job", responseArr[12].data.data.attributes);
      });
  },
  methods: {
    /**
     * Commit API data fetched from Axios to Vuex store,
     * gets mounted from the start
     * @type {array}
     */
    commitApiData(property, value) {
      this.$store.commit("SET_API_DATA", {
        property: property,
        value: value,
      });
    },
  },
};
</script>
