<template>
  <div class="galerie">
    <!-- Header img -->
    <b-container fluid>
      <b-row class="mb-4">
        <b-img
          fluid
          :src="
            node_env === 'production'
              ? galerie.header_bild.data.attributes.url
              : api_url + galerie.header_bild.data.attributes.url
          "
        ></b-img>
      </b-row>
    </b-container>

    <!-- Galerie page -->
    <vue-masonry-wall
      :items="items"
      :options="{ width: 300, padding: 12 }"
      @append="append"
    >
      <template v-slot:default="{ item }">
        <div class="item">
          <b-img
            fluid
            :src="node_env === 'production' ? item.bild : api_url + item.bild"
          ></b-img>
        </div>
      </template>
    </vue-masonry-wall>
  </div>
</template>

<style lang="scss">
.galerie {
  position: relative;
  overflow-x: hidden !important;
  .header-titel {
    position: absolute;
    left: 50%;
    top: 318px;
    color: white;
  }
}
@media only screen and (max-width: 600px) {
  .galerie {
    margin-top: 85px;
  }
}
</style>

<script>
import VueMasonryWall from "vue-masonry-wall";
export default {
  name: "Galerie",
  components: { VueMasonryWall },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    galerie() {
      return this.$store.state.galerie;
    },
    api_url() {
      return this.$store.state.api_url;
    },
    node_env() {
      return this.$store.state.node_env;
    },
  },
  methods: {
    append() {
      this.galerie.bilder.data.forEach((bild) => {
        this.items.push({
          bild: bild.attributes.url,
        });
      });
    },
  },
};
</script>
