<template>
  <!-- Menu -->
  <nav
    v-if="$route.name !== 'KursePlugin'"
    class="menubar navbar fixed-top navbar-expand-sm navbar-light bg-light"
  >
    <b-col md="3">
      <router-link to="/"
        ><b-img
          class="logo"
          fluid
          src="logo.png"
          width="401"
        ></b-img></router-link
    ></b-col>
    <b-col md="9">
      <button
        class="navbar-toggler d-lg-none"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavId"
        aria-controls="collapsibleNavId"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="collapsibleNavId">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li :class="[$route.path == '/' ? 'active' : '', 'nav-item']">
            <router-link
              data-toggle="collapse"
              data-target="#collapsibleNavId"
              class="nav-link"
              to="/"
              >Startseite</router-link
            >
          </li>
          <li class="nav-item dropdown">
            <router-link
              class="nav-link"
              href="#"
              id="dropdownId"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              to="#"
              >Kurse</router-link
            >
            <div class="dropdown-menu" aria-labelledby="dropdownId">
              <router-link
                data-toggle="collapse"
                data-target="#collapsibleNavId"
                class="nav-link"
                to="/staplerkurse"
                >Staplerkurse</router-link
              >
              <router-link
                data-toggle="collapse"
                data-target="#collapsibleNavId"
                class="nav-link"
                to="/hubarbeitsbuhnen"
                >Hubarbeitsbühnen</router-link
              >
              <router-link
                data-toggle="collapse"
                data-target="#collapsibleNavId"
                class="nav-link"
                to="/hebetechnik"
                >Hebetechnik</router-link
              >
              <router-link
                data-toggle="collapse"
                data-target="#collapsibleNavId"
                class="nav-link"
                to="/arbeitssicherheit"
                >Arbeitssicherheit</router-link
              >
              <router-link
                data-toggle="collapse"
                data-target="#collapsibleNavId"
                class="nav-link"
                to="/czv-weiterbildung"
                >CZV Weiterbildung</router-link
              >
              <router-link
                data-toggle="collapse"
                data-target="#collapsibleNavId"
                class="nav-link"
                to="/bau"
                >BAU</router-link
              >
            </div>
          </li>
          <li :class="[$route.path == '/galerie' ? 'active' : '', 'nav-item']">
            <router-link
              data-toggle="collapse"
              data-target="#collapsibleNavId"
              class="nav-link"
              to="/galerie"
              >Galerie</router-link
            >
          </li>
          <li class="nav-item">
            <a
          
              data-target="#collapsibleNavId"
              class="nav-link"
              target="_blank"
              href="https://a1-e-learning.ch"
              >E-learning</a
            >
          </li>
          <li :class="[$route.path == '/uber-uns' ? 'active' : '', 'nav-item']">
            <router-link
              data-toggle="collapse"
              data-target="#collapsibleNavId"
              class="nav-link"
              to="/uber-uns"
              >Über uns</router-link
            >
          </li>
          <li :class="[$route.path == '/agb' ? 'active' : '', 'nav-item']">
            <router-link
              data-toggle="collapse"
              data-target="#collapsibleNavId"
              class="nav-link"
              to="/agb"
              >Agb</router-link
            >
          </li>

          <li
            :class="[$route.path == '/vermietung' ? 'active' : '', 'nav-item']"
          >
            <router-link
              data-toggle="collapse"
              data-target="#collapsibleNavId"
              class="nav-link"
              to="/vermietung"
              >Vermietung</router-link
            >
          </li>

          <li :class="[$route.path == '/job' ? 'active' : '', 'nav-item']">
            <router-link
              data-toggle="collapse"
              data-target="#collapsibleNavId"
              class="nav-link"
              to="/job"
              >Job</router-link
            >
          </li>

          <li class="nav-item dropdown">
            <router-link
              :class="[
                $route.path == '/email' || $route.path == '/individuelle'
                  ? 'active'
                  : '',
                'nav-link',
              ]"
              href="#"
              id="kontaktId"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              to="#"
              >Kontakt</router-link
            >
            <div class="dropdown-menu" aria-labelledby="kontaktId">
              <router-link
                data-toggle="collapse"
                data-target="#collapsibleNavId"
                class="nav-link"
                to="/email"
                >E-mail</router-link
              >
              <router-link
                data-toggle="collapse"
                data-target="#collapsibleNavId"
                class="nav-link"
                to="/individuelle"
              >
                Individuelle Anmeldung</router-link
              >
            </div>
          </li>
        </ul>
      </div>
    </b-col>
  </nav>
</template>
<style lang="scss" scoped>
.logo {
  padding-right: 40px;
}
.menubar {
  .dropdown-menu {
    padding: 15px;
    background-color: #333;
    .nav-link {
      color: #cacaca !important;
    }
  }

  .nav-item {
    margin-top: 0px;
    margin-right: 15px;
    a {
      color: #000 !important;
      text-transform: uppercase;
      font-size: 15px;
      text-decoration: none;
      white-space: nowrap;

      &:hover {
        background-color: #00b252;
        color: white;
      }
    }
  }
  .bg-light {
    background-color: rgba(255, 255, 255, 0.8) !important;
  }
  .active {
    background-color: #00b252;
    a {
      color: white !important;
      padding-left: 5px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .logo {
    max-width: 205px;
    top: 18px;
    position: relative;
  }

  .navbar-toggler {
    position: relative;
    top: -16px;
    margin-left: 227px;
  }
}
</style>
<script>
export default {
  name: "TheMenu",
};
</script>
