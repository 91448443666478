<template>
  <b-container>
    <b-row>
      <b-col md="12">
        <h5 class="mt-3">Kurs hinzufugen:</h5>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col md="4">
        <div class="form-group">
          <h6>Kurs wahlen:</h6>
          <select v-model="name" class="form-control">
            <option value="grundkurs-4-tage">Grundkurs 4 Tage R1/R2</option>
            <option value="kurs-2-tage">Staplerkurs 2 Tage R1/R2</option>
            <option value="stapler-1-tag-r1-r2">
              Staplerkurs 1 Tag R1/R2 mit E-Learning Theorie
            </option>
            <option value="stapler-erganzungskurs-r1-r2">
              Stapler Ergänzungskurs R1/R2
            </option>
            <option value="gegengewicht-teleskopstapler-r1-r4-2-tage">
              Gegengewicht Teleskopstapler R1/R4 2 Tage
            </option>
            <option value="gegengewicht-seitenstapler-r1-r3-2-tage">
              Gegengewicht Seitenstapler R1/R3 2 Tage
            </option>
            <option value="teleskopstapler">
              Teleskopstapler Ergänzung R4 1 Tag
            </option>
            <option value="seitenstapler">
              Seitenstapler Ergänzung R3 1 Tag
            </option>
            <option value="stapler-ch-anerken">
              Stapler CH-Anerkennungsprüfung R1/R2 1/2 Tag
            </option>
            <option value="seiten-stapler-ch-anerkennungs-1tag">
              Stapler CH-Anerkennungskurs 1 Tag
            </option>
            <option value="wiederholungskurse">
              Wiederholungskurs Staplerfahrer
            </option>
            <option value="deichselgerat">Elektro-Deichselgeräte 1 Tag</option>
            <option value="deichselgerat-1-2-tag">
              Elektro-Deichselgeräte 1/2 Tag
            </option>
            <option value="nachprufung-praxis-r1-r2">
              Nachprüfung Praxis R1/R2
            </option>
            <option value="nachprufung-theorie">Nachprüfung Theorie</option>
            <option value="ipafbediener">IPAF Bediener Balsthal</option>
            <option value="ipafbediener-goldwil">IPAF Bediener Goldswil</option>
            <option value="ipaf-einweiser">IPAF Einweiser</option>
            <option value="ipaf-refresher">IPAF Refresher</option>
            <option value="ipaf-trainer">IPAF Trainer</option>
            <option value="auf-abladen">
              Auf- und Abladen von mobilen Arbeitsbühnen
            </option>
            <option value="krane-kat-c">
              Krane Kat. C inkl. Anschlagemittel
            </option>
            <option value="krane-lkw">
              Krane LKW Kat. C inkl. Anschlagemittel
            </option>

            <option value="psaga">PSAgA</option>
            <option value="sibe">SIBE / KOPAS</option>
            <option value="1-hilfe">1. Hilfe Kurs 1 Tag</option>
            <option value="ersthelfer-stufe-1-ivr-2-tage">
              Ersthelfer Stufe 1 IVR 2 Tage
            </option>
            <option value="ersthelfer-stufe-1-ivr-refresh-1-tag">
              Ersthelfer Stufe 1 IVR Refresh 1 Tag
            </option>
            <option value="ladesicherung">Ladesicherung</option>
            <option value="baumaschinen-m3">Baumaschinen M3</option>
            <option value="baumaschinen-schrienbetrieb">
              Baumaschinen Schienenbetrieb
            </option>
            <option value="motorsage-handhabung-1-tag">
              Motorsäge Handhabung 1 Tag
            </option>
            <option value="motorsage-handhabung-2-tage">
              Motorsäge Handhabung 2 Tage
            </option>
            <option value="motorsage-basiskurs-5-tage">
              Motorsäge Basiskurs 5 Tage
            </option>
            <option value="motorsage-weiterfuhrungskurs-5-tage">
              Motorsäge Weiterführungskurs 5 Tage
            </option>
            <option value="kettensagekurs">Bau – Kettensäge</option>
          </select>
        </div>
      </b-col>
      <b-col md="4">
        <h6>Max personen:</h6>
        <input
          :disabled="!name"
          type="number"
          v-model="max_personen"
          class="form-control"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="4">
        <h6>Datum von:</h6>
        <b-form-datepicker
          :disabled="!name || !max_personen"
          v-model="datum_start"
          :date-disabled-fn="dateDisabled"
          class="mb-2 calendar-picker"
        ></b-form-datepicker>
      </b-col>
      <b-col md="4">
        <h6>Datum bis:</h6>
        <b-form-datepicker
          :disabled="!name || !max_personen || !datum_start"
          v-model="datum_end"
          :date-disabled-fn="dateDisabled"
          class="mb-2 calendar-picker"
        ></b-form-datepicker>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-button
          :disabled="!name || !max_personen || !datum_start || !datum_end"
          class="mt-4"
          @click="senden()"
          variant="primary"
          >Senden</b-button
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<style lang="scss" scoped></style>

<script>
import moment from "moment";
import axios from "axios";
export default {
  name: "KursePlugin",
  data() {
    return {
      name: "",
      max_personen: null,
      datum_start: "",
      datum_end: "",
    };
  },
  computed: {
    selectedDatesSequence() {
      var dates = [];
      var start = new Date(this.datum_start);
      var end = new Date(this.datum_end);
      while (start <= end) {
        {
          dates.push(moment(new Date(start)).format("YYYY-MM-DD"));
        }
        start.setDate(start.getDate() + 1);
      }
      return dates;
    },
  },
  methods: {
    dateDisabled(ymd) {
      return ymd < moment(new Date()).format("YYYY-MM-DD");
    },
    senden() {
      for (let i = 0; i < this.selectedDatesSequence.length; i++) {
        axios
          .post(`${process.env.VUE_APP_URL}/api/kurses`, {
            data: {
              name: this.name,
              max_personen: this.max_personen,
              datum: this.selectedDatesSequence[i],
              zeit: this.zeit,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              this.refreshPage();
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },

    refreshPage() {
      window.location.reload();
    },
  },
};
</script>
