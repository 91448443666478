<template>
  <div v-if="$route.name !== 'KursePlugin'">
    <!-- bg green -->
    <b-container fluid>
      <b-row style="margin-bottom: 0px" class="bg-green">
        <b-container> </b-container>
      </b-row>
    </b-container>

    <div class="d-md-flex h-md-100">
      <!-- First Half -->
      <div class="col-md-6 text-left bg-grey p-0 h-md-100">
        <div class="d-md-flex h-100 p-5 justify-content-center">
          <div class="pt-5 pb-5">
            <b-img
              class="mb-5"
              :src="
                node_env === 'production'
                  ? footer.logo.data.attributes.url
                  : api_url + footer.logo.data.attributes.url
              "
              fluid
              alt="logo"
            ></b-img>
            <div v-html="footer.unter_logo_text"></div>
            <div
              class="mt-5 copyright-text"
              v-html="footer.copyright_text"
            ></div>
          </div>
          <!-- copyright text -->
        </div>
      </div>

      <!-- Second Half -->
      <div class="col-md-6 text-left h-md-100">
        <div class="d-md-flex h-md-100 p-5 justify-content-center">
          <div class="kontakt-text" v-html="footer.kontakt_text"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.bg-grey {
  background: #e6e6e6;
}
</style>

<script>
export default {
  name: "TheFooter",
  computed: {
    footer() {
      return this.$store.state.footer;
    },
    node_env() {
      return this.$store.state.node_env;
    },
    api_url() {
      return this.$store.state.api_url;
    },
  },
};
</script>
