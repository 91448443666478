<template>
  <div class="staplerkurse">
    <!-- Header img -->
    <b-container fluid>
      <b-row class="mb-4">
        <b-img
          fluid
          :src="
            node_env === 'production'
              ? kursseiten.arbeitssicherheit_header_bild.data.attributes.url
              : api_url +
                kursseiten.arbeitssicherheit_header_bild.data.attributes.url
          "
        ></b-img>
      </b-row>
    </b-container>

    <b-container>
      <!-- Arbeitssicherheit Kurse List -->
      <b-row class="staplerkurse-titel">
        <b-col md="12">
          <router-link to="arbeitssicherheit/psaga"><h4>PSAgA</h4></router-link>
        </b-col>
      </b-row>
      <b-row class="staplerkurse-titel">
        <b-col md="12">
          <router-link to="arbeitssicherheit/sibe"
            ><h4>SIBE / KOPAS</h4></router-link
          >
        </b-col>
      </b-row>
      <b-row class="staplerkurse-titel">
        <b-col md="12">
          <router-link to="czv-weiterbildung/1-hilfe"
            ><h4>1. Hilfe Kurs 1 Tag</h4></router-link
          >
        </b-col>
      </b-row>
      <b-row class="staplerkurse-titel">
        <b-col md="12">
          <router-link to="arbeitssicherheit/ersthelfer-stufe-1-ivr-2-tage"
            ><h4>Ersthelfer Stufe 1 IVR 2 Tage</h4></router-link
          >
        </b-col>
      </b-row>
      <b-row class="staplerkurse-titel">
        <b-col md="12">
          <router-link to="arbeitssicherheit/sibe"
            ><h4>SIBE / KOPAS</h4></router-link
          >
        </b-col>
      </b-row>
      <b-row class="staplerkurse-titel">
        <b-col md="12">
          <router-link
            to="arbeitssicherheit/ersthelfer-stufe-1-ivr-refresh-1-tag"
            ><h4>Ersthelfer Stufe 1 IVR Refresh 1 Tag</h4></router-link
          >
        </b-col>
      </b-row>
      <b-row class="staplerkurse-titel">
        <b-col md="12">
          <router-link to="arbeitssicherheit/ladesicherung"
            ><h4>Ladesicherung</h4></router-link
          >
        </b-col>
      </b-row>
    </b-container>

    <!-- bg green -->
    <b-container fluid>
      <b-row style="margin-bottom: 0px" class="bg-green">
        <b-container> </b-container>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
.staplerkurse {
  position: relative;
  .header-titel {
    position: absolute;
    left: 50%;
    top: 318px;
    color: white;
  }
}
.staplerkurse-titel {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  a {
    text-decoration: none;
    color: inherit;
  }
  h1 {
    font-weight: 300;
  }

  span {
    color: #777777;
  }
}
@media only screen and (max-width: 600px) {
  .staplerkurse {
    margin-top: 85px;
  }
}
</style>

<script>
export default {
  name: "Arbeitssicherheit",
  computed: {
    kursseiten() {
      return this.$store.state.kursseiten;
    },
    node_env() {
      return this.$store.state.node_env;
    },
    api_url() {
      return this.$store.state.api_url;
    },
  },
};
</script>
