import Vue from "vue";
import Vuex from "vuex";
import set from "lodash/set";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        startseite: [],
        footer: [],
        galerie: [],
        uber_uns: [],
        teams: [],
        nachrichten: [],
        agb: [],
        email: [],
        individuelle: [],
        kurses: [],
        buchungens: [],
        kursseiten: [],
        vermietung: [],
        job: [],
        api_url: process.env.VUE_APP_URL,
        node_env: process.env.NODE_ENV,
    },
    mutations: {
        SET_API_DATA(state, payload) {
            set(state, payload.property, payload.value);
        },
    },
    actions: {},
    modules: {},
});