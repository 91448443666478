<template>
  <div class="slider">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      background="#ababab"
      img-width="1024"
      img-height="280"
      style="text-shadow: 1px 1px 2px #333"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Text slides with image -->

      <b-carousel-slide
        v-for="slide in startseite.slider.data"
        :key="slide.id"
        class="slider-imgs"
        :img-src="
          node_env === 'production'
            ? slide.attributes.url
            : api_url + slide.attributes.url
        "
      >
        <template v-slot:caption>
          <!-- Assuming you want to show the header title on each slide -->
          <h1>{{ startseite.attributes.header_bild_titel }}</h1>
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<style lang="scss" scoped>
.slider {
  margin-top: 79px;
}
.slider-imgs {
  max-height: 714px;
}
</style>

<script>
export default {
  name: "TheSlider",

  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  computed: {
    startseite() {
      return this.$store.state.startseite;
    },
    node_env() {
      return this.$store.state.node_env;
    },
    api_url() {
      return this.$store.state.api_url;
    },
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>
