var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('TheSlider'),_c('b-container',[_c('b-row',{staticClass:"mt-5 kontakt"},[_c('b-col',{attrs:{"md":"4"}},[_c('div',{staticClass:"mr-4 float-left"},[_c('b-img',{attrs:{"fluid":"","src":_vm.node_env === 'production'
                ? _vm.startseite.kontakt_bild.data.attributes.url
                : _vm.api_url + _vm.startseite.kontakt_bild.data.attributes.url}})],1),_c('div',{staticClass:"float-left"},[_c('h5',[_vm._v(_vm._s(_vm.startseite.kontakt_titel))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.startseite.kontakt_text)}})])]),_c('b-col',{attrs:{"md":"4"}},[_c('div',{staticClass:"mr-4 float-left"},[_c('b-img',{attrs:{"fluid":"","src":_vm.node_env === 'production'
                ? _vm.startseite.offnungzeiten_bild.data.attributes.url
                : _vm.api_url + _vm.startseite.offnungzeiten_bild.data.attributes.url}})],1),_c('div',{staticClass:"float-left"},[_c('h5',[_vm._v(_vm._s(_vm.startseite.offnungzeiten_titel))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.startseite.offnungzeiten_text)}})])]),_c('b-col',{attrs:{"md":"4"}},[_c('div',{staticClass:"float-left"},[_c('b-img',{attrs:{"fluid":"","src":_vm.node_env === 'production'
                ? _vm.startseite.ausbildungszentrum_bild.data.attributes.url
                : _vm.api_url +
                  _vm.startseite.ausbildungszentrum_bild.data.attributes.url}})],1),_c('div',{staticClass:"float-right",staticStyle:{"margin-right":"-30px"}},[_c('h5',[_vm._v(_vm._s(_vm.startseite.ausbildungszentrum_titel))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.startseite.ausbildungszentrum_text)}})])])],1)],1),_c('b-container',[_c('b-row',{staticClass:"moto"},[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(_vm.startseite.moto)}})])],1)],1),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"bg-green"},[_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('b-img',{attrs:{"fluid":"","src":_vm.node_env === 'production'
                  ? _vm.startseite.moto_2_bild_1.data.attributes.url
                  : _vm.api_url + _vm.startseite.moto_2_bild_1.data.attributes.url}})],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-img',{attrs:{"fluid":"","src":_vm.node_env === 'production'
                  ? _vm.startseite.moto_2_bild_2.data.attributes.url
                  : _vm.api_url + _vm.startseite.moto_2_bild_2.data.attributes.url}})],1),_c('b-col',{attrs:{"md":"8"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.startseite.moto_2)}})])],1)],1)],1)],1),_c('b-container',[_c('b-row',{staticClass:"mb-5"},[_c('b-col',{attrs:{"md":"4"}},[_c('b-img',{attrs:{"fluid":"","src":_vm.node_env === 'production'
              ? _vm.startseite.vorbereitung_logo_1.data.attributes.url
              : _vm.api_url + _vm.startseite.vorbereitung_logo_1.data.attributes.url}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-img',{attrs:{"fluid":"","src":_vm.node_env === 'production'
              ? _vm.startseite.vorbereitung_logo_2.data.attributes.url
              : _vm.api_url + _vm.startseite.vorbereitung_logo_2.data.attributes.url}})],1)],1)],1),_c('b-container',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('iframe',{attrs:{"width":"100%","height":"315","src":_vm.startseite.youtube_link,"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]),_c('b-col',{staticClass:"gute-vorbereitung",attrs:{"md":"6"}},[_c('div',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(_vm.startseite.gute_vorbereitung)}})])],1)],1),_c('b-container',[_c('b-row',{staticClass:"pre-carousel-text"},[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(_vm.startseite.zertifikate_text)}})])],1)],1),_c('b-container',[_c('b-row',{staticClass:"custom-carousel"},[_c('b-col',{attrs:{"md":"12"}},[_c('carousel',{attrs:{"dots":false,"nav":false,"items":4,"auto-width":true,"autoplay":true}},_vm._l((_vm.startseite.zertifikate_bilder.data),function(bild){return _c('img',{key:bild.id,attrs:{"src":_vm.node_env === 'production'
                ? bild.attributes.url
                : _vm.api_url + bild.attributes.url}})}),0)],1)],1)],1),_c('b-container',[_c('b-row',{staticClass:"pre-carousel-text"},[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(_vm.startseite.partner_text)}})])],1)],1),_c('b-container',[_c('b-row',{staticClass:"custom-carousel"},[_c('b-col',{attrs:{"md":"12"}},[_c('carousel',{attrs:{"dots":false,"nav":false,"items":4,"auto-width":true,"autoplay":true}},_vm._l((_vm.startseite.partner_bilder.data),function(bild){return _c('img',{key:bild.id,attrs:{"src":_vm.node_env === 'production'
                ? bild.attributes.url
                : _vm.api_url + bild.attributes.url}})}),0)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }