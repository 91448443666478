<template>
  <div>
    <TheSlider />

    <!-- Kontakt section -->
    <b-container>
      <b-row class="mt-5 kontakt">
        <b-col md="4">
          <div class="mr-4 float-left">
            <b-img
              fluid
              :src="
                node_env === 'production'
                  ? startseite.kontakt_bild.data.attributes.url
                  : api_url + startseite.kontakt_bild.data.attributes.url
              "
            ></b-img>
          </div>
          <div class="float-left">
            <h5>{{ startseite.kontakt_titel }}</h5>
            <p v-html="startseite.kontakt_text"></p>
          </div>
        </b-col>
        <b-col md="4">
          <div class="mr-4 float-left">
            <b-img
              fluid
              :src="
                node_env === 'production'
                  ? startseite.offnungzeiten_bild.data.attributes.url
                  : api_url + startseite.offnungzeiten_bild.data.attributes.url
              "
            ></b-img>
          </div>
          <div class="float-left">
            <h5>{{ startseite.offnungzeiten_titel }}</h5>
            <p v-html="startseite.offnungzeiten_text"></p>
          </div>
        </b-col>
        <b-col md="4">
          <div class="float-left">
            <b-img
              fluid
              :src="
                node_env === 'production'
                  ? startseite.ausbildungszentrum_bild.data.attributes.url
                  : api_url +
                    startseite.ausbildungszentrum_bild.data.attributes.url
              "
            ></b-img>
          </div>
          <div style="margin-right: -30px" class="float-right">
            <h5>{{ startseite.ausbildungszentrum_titel }}</h5>
            <p v-html="startseite.ausbildungszentrum_text"></p>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!-- Moto -->
    <b-container>
      <b-row class="moto">
        <b-col md="12">
          <div class="text-center" v-html="startseite.moto"></div>
        </b-col>
      </b-row>
    </b-container>

    <!-- Green Background .. -->
    <b-container fluid>
      <b-row class="bg-green">
        <b-container>
          <b-row>
            <b-col md="2">
              <b-img
                fluid
                :src="
                  node_env === 'production'
                    ? startseite.moto_2_bild_1.data.attributes.url
                    : api_url + startseite.moto_2_bild_1.data.attributes.url
                "
              ></b-img>
            </b-col>
            <b-col md="2">
              <b-img
                fluid
                :src="
                  node_env === 'production'
                    ? startseite.moto_2_bild_2.data.attributes.url
                    : api_url + startseite.moto_2_bild_2.data.attributes.url
                "
              ></b-img>
            </b-col>

            <b-col md="8"> <div v-html="startseite.moto_2"></div> </b-col>
          </b-row>
        </b-container>
      </b-row>
    </b-container>

    <!-- temptraining, excellent logos -->
    <b-container>
      <b-row class="mb-5">
        <b-col md="4">
          <b-img
            fluid
            :src="
              node_env === 'production'
                ? startseite.vorbereitung_logo_1.data.attributes.url
                : api_url + startseite.vorbereitung_logo_1.data.attributes.url
            "
          ></b-img>
        </b-col>
        <b-col md="4">
          <b-img
            fluid
            :src="
              node_env === 'production'
                ? startseite.vorbereitung_logo_2.data.attributes.url
                : api_url + startseite.vorbereitung_logo_2.data.attributes.url
            "
          ></b-img>
        </b-col>
      </b-row>
    </b-container>

    <!-- YT Video & Gute vorbereitung -->
    <b-container>
      <b-row>
        <b-col md="6">
          <iframe
            width="100%"
            height="315"
            :src="startseite.youtube_link"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </b-col>
        <b-col class="gute-vorbereitung" md="6">
          <div class="text-center" v-html="startseite.gute_vorbereitung"></div>
        </b-col>
      </b-row>
    </b-container>

    <!-- Zertifikat text -->
    <b-container>
      <b-row class="pre-carousel-text">
        <b-col md="12">
          <div class="text-center" v-html="startseite.zertifikate_text"></div>
        </b-col>
      </b-row>
    </b-container>

    <!-- Zetifikat carousel -->
    <b-container>
      <b-row class="custom-carousel">
        <b-col md="12">
          <carousel
            :dots="false"
            :nav="false"
            :items="4"
            :auto-width="true"
            :autoplay="true"
          >
            <img
              v-for="bild in startseite.zertifikate_bilder.data"
              :key="bild.id"
              :src="
                node_env === 'production'
                  ? bild.attributes.url
                  : api_url + bild.attributes.url
              "
            />
          </carousel>
        </b-col>
      </b-row>
    </b-container>

    <!-- Partner text -->
    <b-container>
      <b-row class="pre-carousel-text">
        <b-col md="12">
          <div class="text-center" v-html="startseite.partner_text"></div>
        </b-col>
      </b-row>
    </b-container>

    <!-- Partner carousel -->
    <b-container>
      <b-row class="custom-carousel">
        <b-col md="12">
          <carousel
            :dots="false"
            :nav="false"
            :items="4"
            :auto-width="true"
            :autoplay="true"
          >
            <img
              v-for="bild in startseite.partner_bilder.data"
              :key="bild.id"
              :src="
                node_env === 'production'
                  ? bild.attributes.url
                  : api_url + bild.attributes.url
              "
            />
          </carousel>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
.kontakt {
  .col-md-4 {
    border: 1px solid black;
    padding: 40px 40px 15px 40px;
  }
}
.moto {
  margin: 100px 0px 50px 0px;
  h1 {
    font-size: 32px !important;
    color: #333;
  }
  span {
    color: #777;
  }
}
.bg-green {
  margin-bottom: 100px;
  img {
    border-radius: 50%;
  }
  background-color: #09a650;
  padding: 40px 0px;

  p {
    font-weight: 300;
    line-height: 10px;
    color: white;
  }
  h1 {
    font-weight: 300;
    color: white;
    font-size: 23px;
    line-height: 35px;
  }
}
.gute-vorbereitung {
  margin: 100px 0px 200px 0px;
  h1 {
    font-weight: 300;
  }
  span {
    color: #9a9898;
  }
  .button-custom {
    background: #00b252;
    padding: 15px;
    color: white;
    &:hover {
      text-decoration: none;
    }
  }
}
.pre-carousel-text {
  margin-bottom: 100px;
  h1 {
    font-weight: 300;
  }
  span {
    font-size: 24px;
    color: #9a9898;
  }
}
.custom-carousel {
  border-top: 1px solid lightgrey;
  padding-top: 40px;
  margin-bottom: 100px;
  img {
    max-width: 245px !important;
    margin: 0px 25px 0px 25px;
  }
}
.footer {
  background: #e6e6e6;
}
</style>

<script>
import TheSlider from "@/components/TheSlider.vue";
import carousel from "vue-owl-carousel";
export default {
  name: "Startseite",
  computed: {
    startseite() {
      return this.$store.state.startseite;
    },
    api_url() {
      return this.$store.state.api_url;
    },
    node_env() {
      return this.$store.state.node_env;
    },
  },
  data() {
    return {
      onSlideStart: "",
    };
  },
  components: {
    TheSlider,
    carousel,
  },
};
</script>
