<template>
  <div class="agb">
    <!-- Header img -->
    <b-container fluid>
      <b-row class="mb-4">
        <b-img
          fluid
          :src="
            node_env === 'production'
              ? agb.header_bild.data.attributes.url
              : api_url + agb.header_bild.data.attributes.url
          "
        ></b-img>
      </b-row>
    </b-container>

    <b-container>
      <!-- AGB titel -->
      <b-row>
        <b-col class="agb-titel" md="12">
          <div v-html="agb.titel"></div>
          <div class="border-grey"></div>
        </b-col>
      </b-row>

      <!-- AGB text -->
      <b-row class="mb-5">
        <b-col class="agb-text" md="12">
          <div v-html="agb.text"></div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
.agb {
  position: relative;
  .header-titel {
    position: absolute;
    left: 50%;
    top: 318px;
    color: white;
  }
}
.agb-titel {
  margin-top: 40px;
  h1 {
    font-weight: 300;
  }
  h6 {
    font-size: 22px;
    line-height: 35px;
  }
  span {
    color: #777;
  }
}
.border-grey {
  margin: 25px 0px 125px 0px;
  width: 75px;
  height: 2px;
  background: #eee;
}
.agb-text {
  p {
    color: #777;
  }
}
@media only screen and (max-width: 600px) {
  .agb {
    margin-top: 85px;
  }
}
</style>

<script>
export default {
  name: "Agb",
  computed: {
    agb() {
      return this.$store.state.agb;
    },
    node_env() {
      return this.$store.state.node_env;
    },
    api_url() {
      return this.$store.state.api_url;
    },
  },
};
</script>
