import Vue from "vue";
import VueRouter from "vue-router";
import Startseite from "../views/Startseite.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Startseite",
    component: Startseite,
  },
  {
    path: "/galerie",
    name: "Galerie",
    component: () => import("../views/Galerie.vue"),
  },
  {
    path: "/uber-uns",
    name: "UberUns",
    component: () => import("../views/UberUns.vue"),
  },
  {
    path: "/agb",
    name: "Agb",
    component: () => import("../views/Agb.vue"),
  },
  {
    path: "/email",
    name: "Email",
    component: () => import("../views/Email.vue"),
  },
  {
    path: "/individuelle",
    name: "IndividuelleAnmeldung",
    component: () => import("../views/IndividuelleAnmeldung.vue"),
  },
  {
    path: "/staplerkurse",
    name: "StaplerKurse",
    component: () => import("../views/StaplerKurse.vue"),
  },
  {
    path: "/staplerkurse/grundkurs-4-tage",
    name: "GrundKurs4Tage",
    component: () => import("../views/GrundKurs4Tage.vue"),
  },
  {
    path: "/staplerkurse/stapler-1-tag-r1-r2",
    name: "StaplerKurs1TagR1R2",
    component: () => import("../views/StaplerKurs1TagR1R2.vue"),
  },
  {
    path: "/staplerkurse/stapler-erganzungskurs-r1-r2",
    name: "StaplerErganzungsKursR1R2",
    component: () => import("../views/StaplerErganzungsKursR1R2.vue"),
  },
  {
    path: "/staplerkurse/seitenstapler-ch-anerkennungskurs-1-tag",
    name: "SeitenStaplerChAnerkennungsKurs1Tag",
    component: () => import("../views/SeitenStaplerChAnerkennungsKurs1Tag.vue"),
  },

  {
    path: "/staplerkurse/gegenwicht-teleskopstapler-r1-r4-2-tage",
    name: "GegengewichtTeleskopStaplerR1R42Tage",
    component: () =>
      import("../views/GegengewichtTeleskopStaplerR1R42Tage.vue"),
  },
  {
    path: "/staplerkurse/gegenwicht-seitenstapler-r1-r3-2-tage",
    name: "GegengewichtSeitenStaplerR1R32Tage",
    component: () => import("../views/GegengewichtSeitenStaplerR1R32Tage.vue"),
  },
  {
    path: "/staplerkurse/elektro-deichselgerate-1-2-tag",
    name: "ElektroDeichselgerate12Tag",
    component: () => import("../views/ElektroDeichselGerate12Tag.vue"),
  },
  {
    path: "/staplerkurse/nachprufung-praxis-r1-r2",
    name: "NachprufungPraxisR1R2",
    component: () => import("../views/NachprufungPraxisR1R2.vue"),
  },
  {
    path: "/staplerkurse/nachprufung-theorie",
    name: "NachprufungTheorie",
    component: () => import("../views/NachprufungTheorie.vue"),
  },
  {
    path: "/kurse-plugin",
    name: "KursePlugin",
    component: () => import("../views/KursePlugin.vue"),
  },
  {
    path: "/staplerkurse/kurs-2-tage",
    name: "GrundKurs2Tage",
    component: () => import("../views/Kurs2Tage.vue"),
  },
  {
    path: "/staplerkurse/teleskopstapler",
    name: "Teleskopstapler",
    component: () => import("../views/Teleskopstapler.vue"),
  },
  {
    path: "/staplerkurse/seitenstapler",
    name: "SeitenStapler",
    component: () => import("../views/SeitenStapler.vue"),
  },
  {
    path: "/staplerkurse/stapler-ch-anerken",
    name: "StaplerAnerken",
    component: () => import("../views/StaplerAnerken.vue"),
  },
  {
    path: "/staplerkurse/wiederholungskurse",
    name: "WiederholungsKurse",
    component: () => import("../views/WiederholungsKurse.vue"),
  },
  {
    path: "/staplerkurse/deichselgerat",
    name: "DeichselGerat",
    component: () => import("../views/DeichselGerat.vue"),
  },
  {
    path: "/hubarbeitsbuhnen",
    name: "Hubarbeitsbuhnen",
    component: () => import("../views/Hubarbeitsbuhnen.vue"),
  },
  {
    path: "/hubarbeitsbuhnen/ipaf-bediener",
    name: "IpafBediener",
    component: () => import("../views/IpafBediener.vue"),
  },
  {
    path: "/hubarbeitsbuhnen/ipaf-bediener-goldwil",
    name: "IpafBedienerGoldwil",
    component: () => import("../views/IpafBedienerGoldwil.vue"),
  },
  {
    path: "/hubarbeitsbuhnen/ipaf-einweiser",
    name: "IpafEinweiser",
    component: () => import("../views/IpafEinweiser.vue"),
  },
  {
    path: "/hubarbeitsbuhnen/ipaf-refresher",
    name: "IpafRefresher",
    component: () => import("../views/IpafRefresher.vue"),
  },
  {
    path: "/hubarbeitsbuhnen/ipaf-trainer",
    name: "IpafTrainer",
    component: () => import("../views/IpafTrainer.vue"),
  },
  {
    path: "/hubarbeitsbuhnen/auf-abladen",
    name: "AufAbladen",
    component: () => import("../views/AufAbladen.vue"),
  },
  {
    path: "/hebetechnik",
    name: "Hebetechnik",
    component: () => import("../views/Hebetechnik.vue"),
  },
  {
    path: "/hebetechnik/krane-kat-c",
    name: "KraneKatC",
    component: () => import("../views/KraneKatC.vue"),
  },
  {
    path: "/hebetechnik/krane-lkw",
    name: "KraneLKW",
    component: () => import("../views/KraneLKW.vue"),
  },
  {
    path: "/arbeitssicherheit",
    name: "Arbeitssicherheit",
    component: () => import("../views/Arbeitssicherheit.vue"),
  },
  {
    path: "/arbeitssicherheit/ersthelfer-stufe-1-ivr-2-tage",
    name: "ErsthelferStufe1Ivr2Tage",
    component: () => import("../views/ErsthelferStufe1Ivr2Tage.vue"),
  },
  {
    path: "/arbeitssicherheit/ersthelfer-stufe-1-ivr-refresh-1-tag",
    name: "ErsthelferStufe1IvrRefresh1Tag",
    component: () => import("../views/ErsthelferStufe1IvrRefresh1Tag.vue"),
  },
  {
    path: "/arbeitssicherheit/ladesicherung",
    name: "Ladesicherung",
    component: () => import("../views/Ladesicherung.vue"),
  },
  {
    path: "/arbeitssicherheit/sibe",
    name: "Sibe",
    component: () => import("../views/Sibe.vue"),
  },
  {
    path: "/arbeitssicherheit/psaga",
    name: "Psaga",
    component: () => import("../views/Psaga.vue"),
  },
  {
    path: "/czv-weiterbildung",
    name: "CZVWeiterbildung",
    component: () => import("../views/CZVWeiterbildung.vue"),
  },
  {
    path: "/czv-weiterbildung/1-hilfe",
    name: "1Hilfe",
    component: () => import("../views/1Hilfe.vue"),
  },
  {
    path: "/bau",
    name: "Bau",
    component: () => import("../views/Bau.vue"),
  },
  {
    path: "/bau/kettensage",
    name: "Kettensagekurs",
    component: () => import("../views/Kettensagekurs.vue"),
  },
  {
    path: "/bau/m3",
    name: "BauM3",
    component: () => import("../views/BauM3.vue"),
  },
  {
    path: "/bau/schienenbetrieb",
    name: "BauSchienenbetrieb",
    component: () => import("../views/BauSchienenbetrieb.vue"),
  },
  {
    path: "/bau/motorsage-handhabung-1-tag",
    name: "MotorsageHandHabung1Tag",
    component: () => import("../views/MotorsageHandHabung1Tag.vue"),
  },
  {
    path: "/bau/motorsage-handhabung-2-tage",
    name: "MotorsageHandHabung2Tage",
    component: () => import("../views/MotorsageHandHabung1Tag.vue"),
  },
  {
    path: "/bau/motorsage-basiskurs-5-tage",
    name: "MotorsageBasisKurs5Tage",
    component: () => import("../views/MotorsageBasisKurs5Tage.vue"),
  },
  {
    path: "/bau/motorsage-weiterführungskurs-5-tage",
    name: "MotorsageweiterfuhrungsKurs5Tage",
    component: () => import("../views/MotorsageWeiterfuhrungskurs5Tage.vue"),
  },
  {
    path: "/vermietung",
    name: "Vermietung",
    component: () => import("../views/Vermietung.vue"),
  },
  {
    path: "/job",
    name: "Job",
    component: () => import("../views/Job.vue"),
  },
  {
    path: "/keinekurse",
    name: "KeineKurse",
    component: () => import("../views/KeineKurse.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
