<template>
  <div class="staplerkurse">
    <!-- Header img -->
    <b-container fluid>
      <b-row class="mb-4">
        <b-img
          fluid
          :src="
            node_env === 'production'
              ? vermietung.vermietung_header_bild.data.attributes.url
              : api_url + vermietung.vermietung_header_bild.data.attributes.url
          "
        ></b-img>
      </b-row>
    </b-container>

    <b-container>
      <b-row>
        <b-col md="12">
          <div v-html="vermietung.vermietung_text"></div>
        </b-col>
      </b-row>
    </b-container>

    <!-- bg green -->
    <b-container fluid>
      <b-row style="margin-bottom: 0px" class="bg-green">
        <b-container> </b-container>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
</style>

<script>
export default {
  name: "Vermietung",
  computed: {
    vermietung() {
      return this.$store.state.vermietung;
    },
    node_env() {
      return this.$store.state.node_env;
    },
    api_url() {
      return this.$store.state.api_url;
    },
  },
};
</script>
