<template>
  <div class="uber-uns">
    <!-- Header img -->
    <b-container fluid>
      <b-row class="mb-4">
        <b-img
          fluid
          :src="
            node_env === 'production'
              ? uber_uns.header_bild.data.attributes.url
              : api_url + uber_uns.header_bild.data.attributes.url
          "
        ></b-img>
      </b-row>
    </b-container>

    <b-container>
      <!-- Uber uns titel -->
      <b-row>
        <b-col class="uber-uns-titel" md="4">
          <div v-html="uber_uns.titel"></div>
        </b-col>
      </b-row>

      <!-- Accordion -->
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-1 variant="info">{{
              uber_uns.philosophie_titel
            }}</b-button>
          </b-card-header>
          <b-collapse
            id="accordion-1"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-card-text
                ><p>{{ uber_uns.philosophie_text }}</p></b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-2 variant="info">{{
              uber_uns.ausbildung_titel
            }}</b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                ><p>{{ uber_uns.ausbildung_text }}</p></b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-3 variant="info">{{
              uber_uns.angebot_titel
            }}</b-button>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                ><p>{{ uber_uns.angebot_text }}</p></b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>

      <!-- Unser team -->
      <b-row>
        <b-col class="uber-uns-titel" md="7">
          <div v-html="uber_uns.unser_team"></div>
        </b-col>
      </b-row>

      <!-- Team photo/info -->
      <b-row>
        <b-col
          class="team-list text-left mb-5"
          v-for="team in teams"
          :key="team.id"
          md="4"
        >
          <b-img
            fluid
            :src="
              node_env === 'production'
                ? team.bild.data.attributes.url
                : api_url + team.bild.data.attributes.url
            "
          ></b-img>
          <ul class="list-unstyled team-info">
            <li>
              <p>
                <b>{{ team.name }}</b>
              </p>
            </li>
            <li>
              <p>{{ team.beruf }}</p>
            </li>
            <li>
              <p>
                <a :href="'mailto:' + team.email">{{ team.email }}</a>
              </p>
            </li>
            <li>
              <p>
                {{ team.tel }}
              </p>
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
.uber-uns {
  position: relative;
  .header-titel {
    position: absolute;
    left: 50%;
    top: 318px;
    color: white;
  }
}
.uber-uns-titel {
  margin-top: 100px;
  margin-bottom: 50px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  h1 {
    font-weight: 300;
  }

  span {
    color: #777777;
  }
}

.team-list {
  position: relative;
  a {
    color: #333;
  }
  p {
    color: #333;
  }
  li {
    line-height: 15px;
  }
  b {
    color: #424242;
  }
  img {
    border-radius: 50%;
    margin-bottom: 30px;
  }
  &:hover {
    z-index: 999;
    .team-info {
      opacity: 0.9;
      transition: 0.5s;
    }
  }
}
.team-info {
  cursor: pointer;
  position: absolute;
  background: #00b252;
  opacity: 0;
  top: 80px;
  padding: 10px;
  left: 35px;
  width: 260px;
}
.btn-info {
  background: #00b252;
  &:hover {
    background: #029345;
  }
}
@media only screen and (max-width: 600px) {
  .uber-uns {
    margin-top: 85px;
  }
}
</style>

<script>
export default {
  name: "UberUns",
  computed: {
    uber_uns() {
      return this.$store.state.uber_uns;
    },
    teams() {
      return this.$store.state.teams;
    },
    node_env() {
      return this.$store.state.node_env;
    },
    api_url() {
      return this.$store.state.api_url;
    },
  },
};
</script>
