<template>
  <div class="staplerkurse">
    <!-- Header img -->
    <b-container fluid>
      <b-row class="mb-4">
        <b-img
          fluid
          :src="
            node_env === 'production'
              ? kursseiten.hebetechnik_header_bild.data.attributes.url
              : api_url + kursseiten.hebetechnik_header_bild.data.attributes.url
          "
        ></b-img>
        <h1 class="header-titel">Hebetechnik</h1>
      </b-row>
    </b-container>

    <b-container>
      <!-- Hebetechnik Kurse List -->
      <b-row class="staplerkurse-titel">
        <b-col md="12">
          <router-link to="keinekurse"><h4>Krane Kat. A</h4></router-link>
        </b-col>
      </b-row>
      <b-row class="staplerkurse-titel">
        <b-col md="12">
          <router-link to="keinekurse"><h4>Krane Kat. B</h4></router-link>
        </b-col>
      </b-row>
      <b-row class="staplerkurse-titel">
        <b-col md="12">
          <router-link to="hebetechnik/krane-kat-c"
            ><h4>Krane Kat. C inkl. Anschlagemittel</h4></router-link
          >
        </b-col>
      </b-row>
      <b-row class="staplerkurse-titel">
        <b-col md="12">
          <router-link to="hebetechnik/krane-lkw"
            ><h4>Krane LKW Kat. C inkl. Anschlagemittel</h4></router-link
          >
        </b-col>
      </b-row>
    </b-container>

    <!-- bg green -->
    <b-container fluid>
      <b-row style="margin-bottom: 0px" class="bg-green">
        <b-container> </b-container>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
.staplerkurse {
  position: relative;
  .header-titel {
    position: absolute;
    left: 50%;
    top: 318px;
    color: white;
  }
}
.staplerkurse-titel {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  a {
    text-decoration: none;
    color: inherit;
  }
  h1 {
    font-weight: 300;
  }

  span {
    color: #777777;
  }
}
@media only screen and (max-width: 600px) {
  .staplerkurse {
    margin-top: 85px;
  }
}
</style>

<script>
export default {
  name: "Hebetechnik",
  computed: {
    kursseiten() {
      return this.$store.state.kursseiten;
    },
    node_env() {
      return this.$store.state.node_env;
    },
    api_url() {
      return this.$store.state.api_url;
    },
  },
};
</script>
